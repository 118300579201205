<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="detailInfo.createDate"
      headerTitle="查看产品审批详情"
      :title="`${detailInfo.createUser || ''}提交的产品审批申请`"
    />
    <div :class="[auditAuthority === 1 && status === 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card name="1" title="基础信息">
        <div class="basics-wrap">
          <my-cell title="申请单号" :value="detailInfo.billNo" />
          <my-cell title="修改备注" :value="remark" />
          <my-cell title="产品大类" :value="detailInfo.parentName" />
          <my-cell title="产品类别" :value="detailInfo.parentProductName" />
          <my-cell title="产品名称" :value="detailInfo.productName" />
          <my-cell title="服务公司" :value="productCompanys" />
          <my-cell title="交付部门" :value="productDepartments" />
          <my-cell title="备注" :value="detailInfo.remark" />
          <img class="status-img" :src="statusImg" />
        </div>
      </my-card>

      <my-card name="2" title="附件">
        <my-cell title="B端合同标准模板" />
        <my-files-view :fileList="detailInfo.contractFiles"/>
        <my-cell title="C端合同标准模板" />
        <my-files-view :fileList="detailInfo.conclusionContractFiles"/>
        <my-cell title="产品报价单模板" />
        <my-files-view :fileList="detailInfo.quotationFiles"/>
        <my-cell title="产品交接单模板" />
        <my-files-view :fileList="detailInfo.receitpFiles"/>
        <my-cell title="客户服务方案模板" />
        <my-files-view :fileList="detailInfo.customerServiceFiles"/>
        <my-cell title="签单指引" />
        <my-files-view :fileList="detailInfo.visaGuideFiles"/>
        <my-cell title="产品介绍" />
        <my-files-view :fileList="detailInfo.publicityFiles"/>
        <my-cell title="交互服务SOP" />
        <my-files-view :fileList="detailInfo.sopFiles"/>
      </my-card>

      <approval-process name="3" v-if="detailInfo.billId" :billId="detailInfo.billId" :module="16" />
    </div>
    <detail-approval-btn v-if="detailInfo.billNo && auditAuthority === 1 && status === 1" :billNo="detailInfo.billNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import { getProductDetail } from '@/api/reimburse'
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);

export default {
  name: "reimburseView",
  data() {
    return {
      detailId: '', // id
      tabsList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '附件',
          key: 2,
        },
        {
          title: '审批流程',
          key: 3,
        },
      ],
      remark: '', // 本次修改备注
      detailInfo: {},
      detailsList: [],
      isUnfold: false,
      detailsTotal: 0,
      statusImg: '',
      auditAuthority: 0,
      productCompanys: [],
      productDepartments: [],
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  mounted() {
    // 查看是否有内容
    this.detailId = this.$route.query.id;
    this.status = this.$route.query.status;
    this.remark = this.$route.query.remark;
    this.auditAuthority = this.$route.query.auditAuthority;
    if (this.detailId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
    if(this.status === 2){ // 驳回
      this.statusImg = rejectImg;
    } else if(this.status === 3){ // 通过
      this.statusImg = passImg;
    }
  },
  methods: {
    getDetailInfo() {
      getProductDetail({productId: this.detailId}).then(res => {
        let obj = res.resultData;
        this.detailInfo = obj;
        this.productCompanys = obj.productCompanys.map(item=> item.serviceCompanyName).join('、')
        this.productDepartments = obj.productDepartments.map(item=> item.departmentName).join('、')
      })
    },
    handleUnfold(){
      this.detailsList = this.detailInfo.details;
      this.isUnfold = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>